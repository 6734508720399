import { FC, useCallback } from 'react';
import styles from './PricingCard.module.scss';
import { PricingCardProps } from './PricingCard.props';
import Link from 'next/link';
import { FeatureEntity } from 'services/graphql/cms';
import { FeaturesGroup } from '../../pages/next/Pricing';
import { Divider, Typography } from 'antd';
import { PlanIcon } from '../../PlanIcon';
import { useAuth0 } from '@auth0/auth0-react';

const { Title } = Typography;

const PricingCard: FC<PricingCardProps> = ({ plan, features, showFeatures = true }) => {
  const { isAuthenticated } = useAuth0();

  const { description, title, price, recurrence, icon } = plan;
  const getFeaturesByGroup = useCallback(
    (group: FeaturesGroup) => {
      const featuresTitle = features?.data
        .sort((a: FeatureEntity, b: FeatureEntity) => a!.attributes!.order - b!.attributes!.order)
        .filter((item: FeatureEntity) => item?.attributes?.showOnCard === true && item.attributes.group === group);

      return featuresTitle.map((feature: FeatureEntity) => {
        if (feature?.attributes?.availableOn?.find((onPlan) => onPlan?.plan?.data?.attributes?.title === title)) {
          const availableOn = feature?.attributes?.availableOn?.find(
            (onPlan) => onPlan?.plan?.data?.attributes?.title === title,
          );

          if (availableOn?.availabilityTitle?.toLocaleLowerCase().includes('limit')) {
            return (
              <li key={feature.id} className={`${styles.containerItem}  ${styles.containerItemUnselected}`}>
                <p>{feature?.attributes?.availableOn[0]?.cardTitle}</p>
              </li>
            );
          }

          return (
            <li className={styles.containerItem} key={feature.id}>
              <p>{availableOn?.cardTitle}</p>
            </li>
          );
        }

        return (
          <li key={feature.id} className={`${styles.containerItem}  ${styles.containerItemUnselected}`}>
            <p>
              {feature?.attributes?.availableOn !== undefined &&
                feature?.attributes?.availableOn !== null &&
                feature?.attributes?.availableOn[0]?.cardTitle}
            </p>
          </li>
        );
      });
    },
    [features, title],
  );

  return (
    <div className={styles.container}>
      <div className={styles.containerContent}>
        <div className={styles.containerTitleWrapper}>
          <h3 className={styles.contentTitle}>
            {icon ? <PlanIcon name={plan?.title?.toLowerCase()} styles={{ width: '1.7em' }} /> : null}
            {title}
          </h3>
        </div>

        <h2 className={styles.contentPrice}>
          <div>
            {price?.currency?.data?.attributes?.symbol}
            {price?.amount}
          </div>

          <p className={styles.contentTime}>{recurrence}</p>
        </h2>
        <p className={styles.contentText}>{description}</p>
      </div>
      {showFeatures && (
        <>
          {!isAuthenticated && (
            <div className={styles.actionButton}>
              {plan.title?.toLocaleLowerCase().includes('pro') ? (
                <Link href={'/register'} className="ant-btn ant-btn-default ant-btn-block ant-btn-lg ant-btn-special">
                  Try for free
                </Link>
              ) : (
                <Link
                  href={'/register'}
                  className={`ant-btn ant-btn-default ant-btn-lg ant-btn-block ${styles.getStartedButton}`}
                >
                  Get started
                </Link>
              )}
            </div>
          )}
          <Divider style={{ margin: '40px 0 32px' }} />

          <ul className={`${styles.containerItems} ${styles.containerItemsApp}`}>
            <img src="/images/theboatapp.svg" alt={'TheBoatApp Logo'} />

            <Title level={4} style={{ margin: '0 0 22px -22px' }}>
              Core Features
            </Title>

            {getFeaturesByGroup('TheBoatApp')}
            {getFeaturesByGroup('General')}
          </ul>

          <ul className={styles.containerItems}>
            <img src="/images/theboatdb.svg" alt={'TheBoatDB Logo'} />
            <Title level={4} style={{ margin: '0 0 22px -22px' }}>
              Core Features
            </Title>
            {getFeaturesByGroup('TheBoatDB')}
          </ul>
        </>
      )}

      <div className={styles.cardFooter}>
        {!isAuthenticated && (
          <div className={styles.footerActionButton}>
            {plan.title?.toLocaleLowerCase().includes('pro') ? (
              <Link href={'/register'} className="ant-btn ant-btn-default ant-btn-block ant-btn-lg ant-btn-special">
                Try for free
              </Link>
            ) : (
              <Link
                href={'/register'}
                className={`ant-btn ant-btn-default ant-btn-lg ant-btn-block ${styles.getStartedButton}`}
              >
                Get started
              </Link>
            )}
          </div>
        )}
        <Link
          href="/pricing#pricing__plan"
          className={`ant-btn ant-btn-link ant-btn-lg ant-btn-block ${styles.footerButtonMore}`}
        >
          Learn More
        </Link>
      </div>
    </div>
  );
};

export { PricingCard };
